import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CachedIcon from "@mui/icons-material/Cached";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DialogButtons } from "../../../components/dialog";
import Header from "../../../components/header";
import Table from "../../../components/table";
import UrlPaths from "../../../constants/UrlPaths";
import { get, put } from "../../../services/HttpClient";
import { branchInfo } from "../../../signals";
import { tokens } from "../../../theme";
import { toVNDCurrency } from "../../../utils/CurencyUtil";
import StoreHistoryDialog from "./StoreHistoryDialog";
import StoreTransferDialog from "./StoreTransferDialog";

const Stores = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();

  const [storedItems, setStoredItems] = useState([]);
  const [openStoreHistoryDialog, setOpenStoreHistoryDialog] = useState(false);
  const [openStoreTransferDialog, setOpenStoreTransferDialog] = useState(false);
  const [selectedStore, setSelectedStore] = useState(null);

  const courseColumns = [
    { field: "id", label: "stores.table.field.id.label" },
    {
      field: "packageValue.packageName",
      label: "stores.table.field.packageName.label",
      valueGetter: ({ packageValue }) => packageValue.packageName
    },
    {
      field: "stockQuantity",
      label: "stores.table.field.stocks.label",
      valueGetter: ({ stockQuantity }) => stockQuantity || 0
    },
    {
      field: "packageValue.singlePrice",
      label: "stores.table.field.singlePrice.label",
      valueGetter: ({ packageValue }) => toVNDCurrency(packageValue.singlePrice)
    }
  ];
  const rowActions = [
    {
      icon: <AddCircleOutlineIcon color="success" />,
      tooltip: t("stores.table.action.addQuantity.label"),
      action: (rowData) => {
        setOpenStoreHistoryDialog(true);
        setSelectedStore(rowData);
      }
    },
    {
      icon: <CachedIcon color="info" />,
      tooltip: t("stores.table.action.transferQuantity.label"),
      action: (rowData) => {
        setOpenStoreTransferDialog(true);
        setSelectedStore(rowData);
      }
    }
  ];

  useEffect(() => {
    refreshData();
  }, [branchInfo.value]);

  const refreshData = () => {
    if (branchInfo.value.uuid) {
      get(`${UrlPaths.ADMIN}/stores`)
        .then((res) => {
          const stores = res.map((x, index) => ({ id: index + 1, ...x }));
          setStoredItems(stores);
        })
        .catch(console.debug);
    }
  };

  const handleClose = () => {
    setOpenStoreHistoryDialog(false);
    setOpenStoreTransferDialog(false);
    setSelectedStore(null);
  };

  return (
    <Box>
      {/* SEARCH & ACTIONS BAR */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={t("header.title.label")} />
      </Box>
      <Box
        backgroundColor={colors.primary[400]}
        display="flex"
        mb="0.5rem"
        p="0.5rem"
        justifyContent="space-between">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h3" fontWeight="bold" marginY="1rem">
              {t("stores.title.label")}
            </Typography>
            <Table
              columnConfig={courseColumns}
              data={storedItems}
              showHeaderToolbar={false}
              rowActions={rowActions}
            />
          </Grid>
        </Grid>
      </Box>
      <StoreHistoryDialog
        open={openStoreHistoryDialog}
        onClose={handleClose}
        onConfirm={(res) => {
          const { uuid, packageValue } = selectedStore;
          const { addedDate, receivedUserUuid, addQuantity } = res;
          const requestBody = {
            storeUuid: uuid,
            branchUuid: branchInfo.value.uuid,
            addedDate,
            receivedUserUuid,
            addQuantity,
            packageName: packageValue.packageName
          };
          put(`${UrlPaths.ADMIN}/stores/${uuid}`, requestBody)
            .then(() => {
              refreshData();
              handleClose();
            })
            .catch(console.debug);
        }}
        buttons={DialogButtons.CANCEL_CONFIRM}
        title={t("stores.dialog.addQuantity.label", {
          packageName: selectedStore?.packageValue?.packageName
        })}
      />
      <StoreTransferDialog
        open={openStoreTransferDialog}
        selectedStore={selectedStore}
        onClose={handleClose}
        onConfirm={(res) => {
          const { uuid } = selectedStore;
          const { selectedBranch, quantity } = res;
          const requestBody = {
            fromBranchUuid: branchInfo.value.uuid,
            toBranchUuid: selectedBranch,
            quantity
          };
          put(`${UrlPaths.ADMIN}/stores/${uuid}/transfer`, requestBody)
            .then(() => {
              refreshData();
              handleClose();
            })
            .catch(console.debug);
        }}
        buttons={DialogButtons.CANCEL_CONFIRM}
        title={t("stores.dialog.transferQuantity.label", {
          packageName: selectedStore?.packageValue?.packageName
        })}
      />
    </Box>
  );
};

export default Stores;
