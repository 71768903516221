import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Autocomplete from "../../../components/autocomplete";
import Dialog from "../../../components/dialog";
import UrlPaths from "../../../constants/UrlPaths";
import { get } from "../../../services/HttpClient";
import { branchInfo } from "../../../signals";

const StoreTransferDialog = ({ onConfirm, onClose, selectedStore, ...props }) => {
  const { t } = useTranslation();
  const [branches, setBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [quantity, setQuantity] = useState(0);

  useEffect(() => {
    get(`${UrlPaths.ADMIN}/branches`).then((branches) => {
      setBranches(branches.filter(({ uuid }) => branchInfo.value.uuid !== uuid));
    });
  }, [selectedStore]);

  useEffect(() => {
    setSelectedBranch(null);
    setQuantity(0);
  }, [onClose, onConfirm]);

  const confirmTransfer = () => {
    onConfirm({
      selectedBranch,
      quantity
    });
  };

  return (
    <Dialog {...props} onClose={onClose} onConfirm={confirmTransfer}>
      <Grid
        container
        flexDirection="column"
        justifyContent="center"
        sx={{
          width: "100%"
        }}
        rowSpacing={4}>
        <Grid item>
          <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
            <Autocomplete
              placeholder="storeTransfer.placeholder.fromBranch"
              disabled
              value={{
                uuid: branchInfo.value.uuid,
                branchName: branchInfo.value.branchName
              }}
              requestConfig={{
                url: `${UrlPaths.ADMIN}/branches`,
                label: "branchName",
                value: "uuid"
              }}></Autocomplete>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
            <InputLabel id="toBranch-label" color="neutral">
              {t("storeTransfer.placeholder.toBranch")}
            </InputLabel>
            <Select
              labelId="toBranch-label"
              id="toBranch"
              label={t("storeTransfer.placeholder.toBranch")}
              MenuProps={{
                style: { zIndex: 15003 }
              }}
              value={selectedBranch}
              onChange={(e) => setSelectedBranch(e.target.value)}
              sx={{ width: "100%" }}>
              {branches.map((branch, index) => (
                <MenuItem key={index} value={branch.uuid}>
                  {branch.branchName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <TextField
            margin="dense"
            id="name"
            autoFocus
            label={t("storeTransfer.placeholder.quantity")}
            onChange={(e) => setQuantity(e.target.value)}
            fullWidth
            size="small"
            variant="outlined"
            sx={{ paddingX: "0.5rem", margin: 0 }}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default StoreTransferDialog;
