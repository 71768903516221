import { Edit } from "@mui/icons-material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Box, Chip, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components/button";
import { DialogButtons } from "../../../components/dialog";
import Header from "../../../components/header";
import Table from "../../../components/table";
import UrlPaths from "../../../constants/UrlPaths";
import { get, post, put } from "../../../services/HttpClient";
import { branchInfo, userProfile } from "../../../signals";
import { tokens } from "../../../theme";
import { getDisplayName, isAdmin, isTeacher } from "../../../utils/UserUtil";
import StaffDetailsDialog from "./StaffDetailsDialog";

const getDepartmentColor = (department) => {
  if (department === "ALL") {
    return "error";
  }
  if (department === "OFFICE") {
    return "info";
  }
  if (department === "ACADEMIC") {
    return "secondary";
  }
  return "warning";
};

const Staffs = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();

  const [staffs, setStaffs] = useState([]);
  const [openStaffDialog, setOpenStaffDialog] = useState(false);
  const [staffDetails, setStaffDetails] = useState(null);
  const [action, setAction] = useState(null);
  const staffCollumns = [
    { field: "id", label: "staffs.table.field.id.label" },
    {
      field: "niceName",
      label: "staffs.table.field.staffName.label"
    },
    {
      field: "mobile",
      label: "staffs.table.field.mobileNumber.label"
    },
    {
      field: "departments",
      label: "staffs.table.field.department.label",
      sortable: false,
      align: "center",
      component: (user) => (
        <Chip
          size="small"
          label={t(`department.${user.departments[0]}.label`)}
          color={getDepartmentColor(user.departments[0])}
          sx={{ fontWeight: "bold" }}
        />
      )
    },
    {
      field: "roles",
      label: "staffs.table.field.roles.label",
      sortable: false,
      valueGetter: (user) =>
        user.roles
          .sort((a, b) => a.localeCompare(b))
          .map((role) => t(`role.${role}.label`))
          .join(", ")
    }
  ];
  const rowActions = [
    {
      icon: <Edit />,
      tooltip: t("staffs.button.edit"),
      action: (rowData) => {
        setAction("edit");
        setOpenStaffDialog(true);
        setStaffDetails(rowData);
      },
      showIf: (rowData) => {
        const currentUser = userProfile.value;
        if (isAdmin(currentUser) || currentUser.uuid === rowData.uuid) {
          return true;
        }
        if (isAdmin(rowData)) {
          return false;
        }
        if (!isTeacher(currentUser) && isTeacher(rowData)) {
          return true;
        }
        return false;
      }
    }
  ];

  useEffect(() => {
    if (branchInfo.value.uuid) {
      refreshData();
    }
  }, [branchInfo.value]);

  const refreshData = () => {
    get(`${UrlPaths.ADMIN}/${branchInfo.value.uuid}/staffs`)
      .then((res) => {
        const newStaffs = res
          .sort(({ roles, departments }) => {
            if (departments.includes("ALL") || roles.includes("ADMINISTRATOR")) {
              return -1;
            } else if (departments.includes("OFFICE")) {
              return 0;
            }
            return 1;
          })
          .map((x, index) => ({
            ...x,
            id: index + 1,
            mobile: getMobileNumber(x.mobile)
          }));
        setStaffs(newStaffs);
      })
      .catch(console.debug);
  };

  const getMobileNumber = (number) =>
    number === "-" || number.startsWith("+") || number.startsWith("0") ? number : "0" + number;

  const handleClose = () => {
    setOpenStaffDialog(false);
    setStaffDetails(null);
  };

  return (
    <Box>
      {/* SEARCH & ACTIONS BAR */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={t("header.title.label")} />
      </Box>
      <Box
        backgroundColor={colors.primary[400]}
        mb="0.5rem"
        p="0.5rem"
        justifyContent="space-between">
        <Typography variant="h3" fontWeight="bold" marginY="1rem">
          {t("staffs.header.label")}
        </Typography>
        <Button
          startIcon={<AddCircleOutlineIcon />}
          variant="contained"
          color="success"
          sx={{
            marginBottom: "0.5rem"
          }}
          onClick={() => {
            setAction("new");
            setOpenStaffDialog(true);
          }}>
          {t("staffs.button.new")}
        </Button>
        <Table
          columnConfig={staffCollumns}
          data={staffs}
          showHeaderToolbar={false}
          rowActions={rowActions}
        />
      </Box>
      <StaffDetailsDialog
        open={openStaffDialog}
        data={staffDetails}
        action={action}
        onClose={handleClose}
        onConfirm={(res) => {
          if (!staffDetails?.uuid) {
            post(`${UrlPaths.ADMIN}/staffs`, res)
              .then(() => {
                refreshData();
                handleClose();
              })
              .catch(console.debug);
          } else {
            put(`${UrlPaths.ADMIN}/staffs/${staffDetails.uuid}`, res)
              .then(() => {
                refreshData();
                handleClose();
              })
              .catch(console.debug);
          }
        }}
        buttons={DialogButtons.CANCEL_CONFIRM}
        title={t(`staffs.dialog.${staffDetails?.uuid ? "update" : "new"}.title`, {
          staffName: getDisplayName(staffDetails)
        })}
      />
    </Box>
  );
};

export default Staffs;
