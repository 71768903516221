import { FormControl, Grid, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Autocomplete from "../../../components/autocomplete";
import Dialog from "../../../components/dialog";
import { DEPARTMENTS } from "../../../constants/Department";
import UrlPaths from "../../../constants/UrlPaths";
import { branchInfo } from "../../../signals";
import { DEFAULT_DATE_FORMAT } from "../../../utils/TimeUtil";

const INITIAL_DATA = {
  addedDate: moment(),
  receivedUserUuid: "",
  addQuantity: 0,
  receivedUserValue: {
    uuid: "",
    niceName: ""
  }
};

const StoreHistoryDialog = ({ onConfirm, onClose, ...props }) => {
  const { t } = useTranslation();
  const [storeHistory, setStoreHistory] = useState(INITIAL_DATA);

  useEffect(() => {
    setStoreHistory(INITIAL_DATA);
  }, [onClose, onConfirm]);

  const updateFieldValue = (fieldName, fieldValue) => {
    setStoreHistory({ ...storeHistory, [fieldName]: fieldValue });
  };

  const confirmAddStoreHistory = () => {
    onConfirm(storeHistory);
    setStoreHistory(INITIAL_DATA);
  };

  return (
    <Dialog {...props} onClose={onClose} onConfirm={confirmAddStoreHistory}>
      <Grid
        container
        flexDirection="column"
        justifyContent="center"
        sx={{
          width: "100%"
        }}
        rowSpacing={4}>
        <Grid item>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              id="class-transfer-date"
              format={DEFAULT_DATE_FORMAT}
              value={storeHistory.addedDate}
              onChange={(e) => {
                updateFieldValue("addedDate", e);
              }}
              label={t("stores.dialog.addedDate.label")}
              sx={{ width: "100%", paddingX: "0.5rem" }}
              slotProps={{
                textField: { size: "small" },
                popper: {
                  style: {
                    zIndex: 15003
                  }
                }
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item>
          <TextField
            margin="dense"
            id="name"
            autoFocus
            label={t("stores.dialog.addedQuantity.label")}
            onChange={(e) => updateFieldValue("addQuantity", e.target.value)}
            fullWidth
            size="small"
            variant="outlined"
            sx={{ paddingX: "0.5rem", margin: 0 }}
          />
        </Grid>
        <Grid item>
          <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
            <Autocomplete
              placeholder="stores.dialog.receiver.label"
              value={storeHistory.receiverValue}
              onChange={(e, value) => {
                if (value) {
                  setStoreHistory({
                    ...storeHistory,
                    receivedUserUuid: value.uuid || "",
                    receivedUserValue: value
                  });
                } else {
                  setStoreHistory({
                    ...storeHistory,
                    receivedUserUuid: "",
                    receivedUserValue: {
                      uuid: "",
                      niceName: ""
                    }
                  });
                }
              }}
              requestConfig={{
                url: `${UrlPaths.ADMIN}/${branchInfo.value.uuid}/staffs`,
                responseField: (results) =>
                  results.filter(({ departments }) =>
                    ["ALL", DEPARTMENTS.OFFICE].includes(departments[0])
                  ),
                label: "niceName"
              }}></Autocomplete>
          </FormControl>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default StoreHistoryDialog;
