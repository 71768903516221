import { Delete } from "@mui/icons-material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import HistoryIcon from "@mui/icons-material/History";
import IsoIcon from "@mui/icons-material/Iso";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  ButtonGroup,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { Button } from "../../../components/button";
import Dialog, { DialogButtons } from "../../../components/dialog";
import Header from "../../../components/header";
import Parent from "../../../components/parent-info";
import Status from "../../../components/status";
import Table from "../../../components/table";
import UrlPaths from "../../../constants/UrlPaths";
import { deleteById, get, post, put } from "../../../services/HttpClient";
import { userProfile } from "../../../signals";
import { tokens } from "../../../theme";
import {
  DEFAULT_DATE_FORMAT,
  formatDate,
  getAge,
  toDayOfWeekShorten
} from "../../../utils/TimeUtil";
import { getDisplayName, isTeacher } from "../../../utils/UserUtil";
import AddStudentDialog from "./AddStudentDialog";
import LeaveOfAbsenceDialog from "./LeaveOfAbsenceDialog";
import TransferClassDialog from "./TransferClassDialog";
import UpdateSessionsDialog from "./UpdateSessionsDialog";

const ClassDetails = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const { classId } = useParams();

  const [deletionRow, setDeletionRow] = useState(null);
  const [transferRow, setTransferRow] = useState(null);
  const [disableRow, setDisableRow] = useState(null);
  const [disableNote, setDisableNote] = useState("");
  const [disabledDate, setDisabledDate] = useState(moment());
  const [updateSessionsRow, setUpdateSessionsRow] = useState(null);
  const [loaRow, setLoaRow] = useState(null);
  const [addStudent, setAddStudent] = useState(false);
  const [data, setData] = useState([]);
  const [classHistories, setClassHistories] = useState([]);
  const [selectedScheduleUuids, setSelectedScheduleUuids] = useState([]);
  const [studentStatus, setStudentStatus] = useState("");
  const [classInfo, setClassInfo] = useState({
    classDetails: {}
  });

  const columns = [
    { field: "id", label: "classDetails.table.field.id.label", sortable: false },
    {
      field: "studentName",
      label: "classDetails.table.field.studentName.label",
      valueGetter: ({ studentName, studentUuid, birthday }) => (
        <>
          <Link to={`../../students/details/${studentUuid}`}>
            <Typography sx={{ fontWeight: "bold", color: colors.greenAccent[400] }}>
              {studentName}
            </Typography>
          </Link>
          {birthday ? (
            <>
              <span>{formatDate(birthday)}</span>&nbsp;
              <span style={{ fontStyle: "italic" }}>
                {t("studentList.table.field.age.label", { age: getAge(birthday) })}
              </span>
            </>
          ) : null}
        </>
      )
    },
    {
      field: "parent.username",
      label: "classDetails.table.field.parentName.label",
      valueGetter: (data) => (
        <Parent parentName={getDisplayName(data.parent || {})} phoneNumber={data.parent?.mobile} />
      )
    },
    {
      field: "registeredQuantity",
      label: "classDetails.table.field.totalSessions.label",
      valueGetter: ({ registeredQuantity, transferQuantity }) =>
        (registeredQuantity || 0) - (transferQuantity || 0)
    },
    {
      field: "completedQuantity",
      label: "classDetails.table.field.learnedSessions.label",
      valueGetter: ({ completedQuantity }) => completedQuantity || 0
    },
    {
      field: "remainingSessions",
      label: "classDetails.table.field.remainingSessions.label",
      valueGetter: ({ registeredQuantity, completedQuantity, transferQuantity }) =>
        (registeredQuantity || 0) - ((completedQuantity || 0) + (transferQuantity || 0))
    },
    {
      field: "startDate",
      label: "classDetails.table.field.startDate.label",
      valueGetter: ({ startDate }) => formatDate(startDate)
    },
    {
      field: "endDate",
      label: "classDetails.table.field.endDate.label",
      valueGetter: ({ endDate }) => formatDate(endDate)
    },
    {
      field: "status",
      label: "classDetails.table.field.classStatus.label",
      sortable: false,
      align: "center",
      component: (rowData) => (
        <Status
          prefix="classDetails.status"
          postfix="label"
          status={rowData.status}
          type="STUDENT_SCHEDULE"
        />
      )
    }
  ];
  const rowActions = [
    {
      icon: <IsoIcon />,
      tooltip: t("classDetails.table.action.addRemoveSessions.label"),
      action: (rowData) => {
        setUpdateSessionsRow(rowData);
      },
      showIf: (rowData) =>
        !isTeacher(userProfile.value) &&
        !["STUDENT_SKIP_CLASS", "STUDENT_INACTIVE", "STUDENT_LEAVE_OF_ABSENCE"].includes(
          rowData.status
        )
    },
    {
      icon: <TransferWithinAStationIcon />,
      tooltip: t("classDetails.table.action.transfer.label"),
      action: (rowData) => {
        setTransferRow(rowData);
      },
      showIf: (rowData) =>
        !isTeacher(userProfile.value) &&
        !["STUDENT_SKIP_CLASS", "STUDENT_INACTIVE", "STUDENT_LEAVE_OF_ABSENCE"].includes(
          rowData.status
        )
    },
    {
      icon: <HistoryIcon />,
      tooltip: t("classDetails.table.action.loa.label"),
      action: (rowData) => {
        setLoaRow(rowData);
      },
      showIf: (rowData) =>
        !isTeacher(userProfile.value) &&
        !["STUDENT_SKIP_CLASS", "STUDENT_INACTIVE", "STUDENT_LEAVE_OF_ABSENCE"].includes(
          rowData.status
        )
    },
    {
      icon: <Delete />,
      tooltip: t("classDetails.table.action.delete.label"),
      action: (rowData) => {
        setDeletionRow(rowData);
      },
      showIf: (rowData) =>
        !isTeacher(userProfile.value) &&
        !["STUDENT_SKIP_CLASS", "STUDENT_LEAVE_OF_ABSENCE"].includes(rowData.status)
    },
    {
      icon: <HighlightOffIcon />,
      tooltip: t("classDetails.table.action.disable.label"),
      action: (rowData) => {
        setDisableRow(rowData);
      },
      showIf: (rowData) =>
        !isTeacher(userProfile.value) &&
        !["STUDENT_SKIP_CLASS", "STUDENT_INACTIVE", "STUDENT_LEAVE_OF_ABSENCE"].includes(
          rowData.status
        )
    }
  ];

  const classTeacherHistorycolumns = [
    {
      field: "activeDate",
      label: "classDetails.classHistory.table.activeDate",
      sortable: false,
      valueGetter: ({ teacherActiveDate }) => formatDate(teacherActiveDate)
    },
    {
      field: "teacherName",
      label: "classDetails.classHistory.table.teacher",
      sortable: false
    },
    {
      field: "additionalInfo",
      label: "classDetails.classHistory.table.scheduleDetails",
      sortable: false
    }
  ];

  useEffect(() => {
    refreshData();
  }, [classId]);

  useEffect(() => {
    loadStudentList();
  }, [selectedScheduleUuids, studentStatus]);

  const loadStudentList = () => {
    post(`${UrlPaths.CLASSES}/schedule/students`, {
      classScheduleUuids: selectedScheduleUuids,
      studentStatus: studentStatus || undefined
    })
      .then((res) => setData(res.map((x, index) => ({ id: index + 1, ...x }))))
      .catch(console.debug);
  };

  const refreshData = () => {
    get(`${UrlPaths.CLASSES}/${classId}`)
      .then((res) => {
        setClassInfo(res);
        if (res.classDetails.schedule.length > 0) {
          const scheduleUuids = res.classDetails.schedule.map(({ uuid }) => uuid);
          setSelectedScheduleUuids(scheduleUuids);
        }
      })
      .catch(console.debug);

    get(`${UrlPaths.CLASSES}/${classId}/classTeacherHistories`)
      .then((res) => {
        setClassHistories(res);
      })
      .catch(console.debug);
  };

  const handleClose = () => {
    setTransferRow(null);
    setDeletionRow(null);
    setUpdateSessionsRow(null);
    setLoaRow(null);
    setDisableRow(null);
    setAddStudent(false);
  };

  return (
    <Box>
      {/* SEARCH & ACTIONS BAR */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={t("header.title.label")} />
      </Box>
      <Box backgroundColor={colors.primary[400]} mb="0.5rem" p="0.5rem">
        {/** GENERAL INFORMATION SECTION */}
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              backgroundColor: colors.greenAccent[600]
            }}>
            <Typography variant="h4" fontWeight="bolder">
              {t("classDetails.generalInformation.title")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2} columns={16}>
              <Grid item xs={8}>
                <Grid
                  container
                  p="1rem"
                  flexDirection="column"
                  sx={{
                    width: "100%"
                  }}
                  rowSpacing={4}>
                  <Grid item>
                    <TextField
                      disabled
                      value={classInfo.classDetails.branchName || ""}
                      margin="dense"
                      id="name"
                      label={t("classDetails.placeholder.branchName")}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      size="small"
                      variant="outlined"
                      sx={{ paddingX: "0.5rem", margin: 0 }}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      disabled
                      value={classInfo.classDetails.className || ""}
                      margin="dense"
                      id="name"
                      label={t("classDetails.placeholder.className")}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      size="small"
                      variant="outlined"
                      sx={{ paddingX: "0.5rem", margin: 0 }}
                    />
                  </Grid>
                  <Grid item>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        id="start-date"
                        label={t("classDetails.placeholder.startDate")}
                        disabled
                        format={DEFAULT_DATE_FORMAT}
                        value={moment(new Date(classInfo.classDetails.startDate))}
                        sx={{ width: "100%", paddingX: "0.5rem" }}
                        slotProps={{
                          textField: { size: "small" },
                          popper: {
                            style: {
                              zIndex: 15003
                            }
                          }
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item>
                    <FormControl sx={{ width: "100%" }} size="small">
                      <TextField
                        margin="dense"
                        id="name"
                        disabled
                        value={t(`class.status.${classInfo.classDetails.classStatus}.label`)}
                        InputLabelProps={{ shrink: true }}
                        label={t("classDetails.placeholder.status")}
                        fullWidth
                        size="small"
                        variant="outlined"
                        sx={{ paddingX: "0.5rem", margin: 0 }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sx={{ width: "100%", paddingX: "0.5rem" }}>
                    {(classInfo.classDetails.schedule || []).map(
                      ({ teacherName, timeSlot, dayOfWeek, classroomName, uuid }, index) => (
                        <FormControlLabel
                          key={index}
                          sx={{ paddingX: "0.5rem", marginRight: 0 }}
                          value={uuid}
                          checked={selectedScheduleUuids.includes(uuid)}
                          control={<Checkbox />}
                          onChange={(e) => {
                            const { value, checked } = e.target;
                            const newSelectedUuids = checked
                              ? [...selectedScheduleUuids, value]
                              : selectedScheduleUuids.filter((x) => x !== value);
                            setSelectedScheduleUuids(newSelectedUuids);
                          }}
                          label={`${teacherName} / ${timeSlot} ${t(
                            "timetable.weekDay." + toDayOfWeekShorten(dayOfWeek) + ".label"
                          )} (${classroomName})`}
                        />
                      )
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={8}>
                <Grid
                  container
                  p="1rem"
                  flexDirection="column"
                  sx={{
                    width: "100%"
                  }}
                  rowSpacing={4}>
                  <Grid item>
                    <TextField
                      margin="dense"
                      id="name"
                      label={t("classDetails.placeholder.learning")}
                      disabled
                      value={classInfo.learningStudents || 0}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      size="small"
                      variant="outlined"
                      sx={{ paddingX: "0.5rem", margin: 0 }}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      margin="dense"
                      id="name"
                      label={t("classDetails.placeholder.trial")}
                      disabled
                      value={classInfo.trialStudents || 0}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      size="small"
                      variant="outlined"
                      sx={{ paddingX: "0.5rem", margin: 0 }}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      margin="dense"
                      id="name"
                      label={t("classDetails.placeholder.debtor")}
                      disabled
                      value={classInfo.inDebtStudents || 0}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      size="small"
                      variant="outlined"
                      sx={{ paddingX: "0.5rem", margin: 0 }}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      margin="dense"
                      id="name"
                      label={t("classDetails.placeholder.loa")}
                      disabled
                      value={classInfo.loaStudents || 0}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      size="small"
                      variant="outlined"
                      sx={{ paddingX: "0.5rem", margin: 0 }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        {/** CLASS HISTORY SECTION */}
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              backgroundColor: colors.greenAccent[600]
            }}>
            <Typography variant="h4" fontWeight="bolder">
              {t("classDetails.classHistory.title")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <Grid
                item
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  paddingX: "0.5rem"
                }}>
                <Table
                  columnConfig={classTeacherHistorycolumns}
                  data={classHistories}
                  showHeaderToolbar={false}
                  showFooterToolbar={false}
                  maxHeight="600px"></Table>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        {/** CLASS STUDENTS SECTION */}
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              backgroundColor: colors.greenAccent[600]
            }}>
            <Typography variant="h4" fontWeight="bolder">
              {t("classDetails.studentInformation.title")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              backgroundColor={colors.primary[400]}
              display="flex"
              mb="0.5rem"
              p="0.5rem"
              justifyContent="space-between">
              <Grid item xs={4}>
                <FormControl sx={{ minWidth: 150 }} size="small">
                  <InputLabel id="class-status-label" color="neutral">
                    {t("classDetails.placeholder.status")}
                  </InputLabel>
                  <Select
                    labelId="class-status-label"
                    id="class-status"
                    value={studentStatus}
                    onChange={(e) => setStudentStatus(e.target.value)}
                    label={t("classDetails.placeholder.status")}>
                    <MenuItem value="">&nbsp;</MenuItem>
                    <MenuItem value="STUDENT_LEARNING">
                      {t("classDetails.status.STUDENT_LEARNING.label")}
                    </MenuItem>
                    <MenuItem value="STUDENT_TRIAL">
                      {t("classDetails.status.STUDENT_TRIAL.label")}
                    </MenuItem>
                    <MenuItem value="STUDENT_IN_DEBT">
                      {t("classDetails.status.STUDENT_IN_DEBT.label")}
                    </MenuItem>
                    <MenuItem value="STUDENT_LEAVE_OF_ABSENCE">
                      {t("classDetails.status.STUDENT_LEAVE_OF_ABSENCE.label")}
                    </MenuItem>
                    <MenuItem value="STUDENT_COMPLETED">
                      {t("classDetails.status.STUDENT_COMPLETED.label")}
                    </MenuItem>
                    <MenuItem value="STUDENT_INACTIVE">
                      {t("classDetails.status.STUDENT_INACTIVE.label")}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={4}
                borderRadius="3px"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: colors.grey[900]
                }}></Grid>
              <Grid item xs={4}>
                <ButtonGroup variant="contained" aria-label="outlined primary button group">
                  <Button
                    variant="contained"
                    color="success"
                    disabled={isTeacher(userProfile.value)}
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={() => setAddStudent(true)}>
                    {t("classDetails.button.addStudent")}
                  </Button>
                </ButtonGroup>
              </Grid>
            </Box>
            <Table
              columnConfig={columns}
              rowActions={rowActions}
              data={data}
              showHeaderToolbar={false}
              showFooterToolbar={false}
              maxHeight="600px"
            />
          </AccordionDetails>
        </Accordion>
      </Box>
      <AddStudentDialog
        open={addStudent}
        schedule={classInfo.classDetails.schedule || []}
        onClose={handleClose}
        onConfirm={(res) => {
          const request = {
            classScheduleUuids: res.classScheduleUuids,
            studentUuid: res.studentUuid,
            startDate: res.startDate.toDate(),
            numberOfSessions: Number(res.sessions),
            note: res.note,
            status: res.status
          };
          post(`${UrlPaths.CLASSES}/${classId}/schedule/students`, request)
            .then(() => {
              // TODO check refreshing here
              refreshData();
              handleClose();
            })
            .catch(console.debug);
        }}
        buttons={DialogButtons.CANCEL_SAVE}
        title={t("classDetails.dialog.addStudent.title")}
      />
      <UpdateSessionsDialog
        open={!!updateSessionsRow}
        data={updateSessionsRow}
        onClose={handleClose}
        onConfirm={(res) => {
          const request = {
            numberOfSessions: Number(res.numberOfSessions),
            note: res.note
          };
          put(
            `${UrlPaths.CLASSES}/${classId}/schedule/students/${res.studentUuid}/sessions`,
            request
          )
            .then(() => {
              refreshData();
              handleClose();
            })
            .catch(console.debug);
        }}
        buttons={DialogButtons.CANCEL_SAVE}
        title={t("classDetails.dialog.addRemoveSessions.title")}
      />
      <TransferClassDialog
        open={!!transferRow}
        data={transferRow}
        onClose={handleClose}
        onConfirm={(res) => {
          const {
            studentUuid,
            classUuid,
            classTransferDate,
            transferSessions,
            transferDate,
            note
          } = res;
          put(`${UrlPaths.CLASSES}/${classId}/schedule/students/${studentUuid}/transfer`, {
            newClassUuid: classUuid,
            currentScheduleEndDate: classTransferDate,
            numberOfSessions: transferSessions,
            newScheduleStartDate: transferDate,
            note
          })
            .then(() => {
              refreshData();
              handleClose();
            })
            .catch(console.debug);
        }}
        buttons={DialogButtons.CANCEL_SAVE}
        title={t("classDetails.dialog.transferStudent.title")}
      />
      <LeaveOfAbsenceDialog
        open={!!loaRow}
        data={loaRow}
        onClose={handleClose}
        onConfirm={(res) => {
          const request = {
            effectiveDate: res.loaDate,
            note: res.note,
            loaSessions: res.loaSessions
          };
          post(`${UrlPaths.CLASSES}/${classId}/schedule/students/${res.studentUuid}/loa`, request)
            .then(() => {
              refreshData();
              handleClose();
            })
            .catch(console.debug);
        }}
        buttons={DialogButtons.CANCEL_SAVE}
        title={t("classDetails.dialog.loa.title")}
      />
      <Dialog
        open={!!deletionRow}
        data={deletionRow}
        onClose={handleClose}
        onConfirm={() => {
          deleteById(`${UrlPaths.CLASSES}/${classId}/schedule/students`, deletionRow.studentUuid)
            .then(() => {
              refreshData();
              handleClose();
            })
            .catch(console.debug);
        }}
        buttons={DialogButtons.CANCEL_DELETE}
        headerBgColor="#d32f2f"
        headerTitleColor="white"
        title={t("classDetails.dialog.deleteStudent.title")}>
        <Typography variant="h5" color={colors.grey[100]} sx={{ m: "0 0 5px 0" }}>
          <div
            style={{ marginBottom: "1rem" }}
            dangerouslySetInnerHTML={{
              __html: t("classDetails.dialog.student.confirmTitle", {
                studentName: deletionRow?.studentName
              })
            }}></div>
          <div>{t("classDetails.dialog.deleteStudent.description")}</div>
        </Typography>
      </Dialog>
      <Dialog
        open={!!disableRow}
        data={disableRow}
        onClose={handleClose}
        onConfirm={() => {
          post(`${UrlPaths.STUDENTS}/${disableRow.studentUuid}/schedule/disable`, {
            note: disableNote,
            disabledDate
          })
            .then(() => {
              refreshData();
              handleClose();
            })
            .catch(console.debug);
        }}
        buttons={[
          {
            type: "discard",
            id: "cancel",
            label: "common.button.cancel",
            variant: "outlined"
          },
          {
            type: "confirm",
            id: "confirm",
            label: "common.button.confirm",
            color: "error"
          }
        ]}
        headerBgColor="#d32f2f"
        headerTitleColor="white"
        title={t("classDetails.dialog.disableStudent.title")}>
        <Typography variant="h5" color={colors.grey[100]} sx={{ m: "0 0 5px 0" }}>
          <div
            style={{ marginBottom: "1rem" }}
            dangerouslySetInnerHTML={{
              __html: t("classDetails.dialog.student.confirmTitle", {
                studentName: disableRow?.studentName
              })
            }}></div>
          <div
            dangerouslySetInnerHTML={{
              __html: t("classDetails.dialog.disableStudent.description")
            }}></div>
        </Typography>
        <TextField
          margin="dense"
          id="name"
          label={t("addStudent.placeholder.note")}
          value={disableNote || ""}
          onChange={(e) => setDisableNote(e.target.value)}
          fullWidth
          size="small"
          variant="outlined"
        />
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            id="disabled-date"
            label={t("classDetails.placeholder.disabledDate")}
            format={DEFAULT_DATE_FORMAT}
            value={disabledDate}
            sx={{ width: "100%", marginTop: "1rem" }}
            onChange={(e) => {
              if (e?.isValid()) {
                setDisabledDate(e);
              }
            }}
            slotProps={{
              textField: { size: "small" },
              popper: {
                style: {
                  zIndex: 15003
                }
              }
            }}
          />
        </LocalizationProvider>
      </Dialog>
    </Box>
  );
};

export default ClassDetails;
